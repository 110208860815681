<template>
    <modal :title="$t('Updates')" @close="$emit('close')" :ifPrevopen="ifPrevopen" :key="this.componentKey" :ifNextopen="ifNextopen" @prevOpen="prevOpen" @nextOpen="nextOpen">
        <template v-slot:head-button>
            <div class="dropdown" v-if="perms[421]">
                <button class="btn btn-ghost-secondary btn-icon btn-sm fs-16" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i class="bx bx-dots-vertical-rounded align-bottom"></i>
                </button>
                <div class="dropdown-menu dropdown-menu-end">
                    <button class="dropdown-item" v-if="perms[421]" @click="createEvent">{{ $t('addToCalendar') }}</button>
                </div>
            </div>
        </template>
        <template v-slot:body>

            <b-row>
                <b-col>
                    <div>
                        <b-tabs pills >
                            <b-tab >
                                <template #title>
                                    <span class="d-block d-sm-none"><i class="ri-information-line"></i></span>
                                    <span class="d-none d-sm-block">{{ $t('AbouttheUpdate') }}</span> 
                                </template>
                                <div class="mt-1">
                                    
                                    <b-row>
                                        <b-col>
                                            <b-card>
                                                <div class="d-flex">
                                                    <div class="flex-grow-1 click" >
                                                        <h4><b>{{form.update}}</b> <span :class="`fs-14 ${mutateStatus(form.status).color}`">{{ mutateStatus(form.status).name }}</span></h4>
                                                        <p class="text-muted mb-0"><b>{{ $t('version') }}</b>: {{form.version}}</p>
                                                        <p class="text-muted"><b>{{ $t('deadLine') }}</b>: {{form.deadlineDate}}</p>
                                                    </div>
                                                    <div class="flex-shrink-0" v-if="(perms[372] || perms[2000]) && this.form.createWorkerId == this.user.userid">
                                                        <div>
                                                            <button
                                                                v-if="(perms[372] || perms[2000]) && this.form.createWorkerId == this.user.userid"
                                                                @click="showModal = true"
                                                                class="btn btn-light"
                                                                data-bs-toggle="tooltip"
                                                                data-bs-placement="top"
                                                                title="Edit"
                                                            >
                                                            <i class="ri-pencil-fill align-bottom"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>

                                                <template v-if="form.comment">

                                                    <hr />

                                                    <h5 class="text-left"><b>{{$t('desc')}}</b></h5>

                                                    <div class="ql-container">
                                                        <div class="ql-editor" v-html="form.comment">
                                                        </div>
                                                    </div>

                                                </template>

                                            </b-card>
                                        </b-col>
                                    </b-row>

                                    <b-card>

                                        <h5 class="fw-semibold text-uppercase mb-3 border-bottom pb-3">🔥 {{ $t('NewOpportunities') }}</h5>

                                        <template 
                                            v-if="form.opportunities.length > 0"
                                            v-for="(item, index) in this.form.opportunities" 
                                            :key="index"
                                        >
                                            <div class="d-flex align-items-center border border-dashed p-2 rounded mb-2 mt-1" >
                                                <div class="avatar-xs copy" >
                                                    <div class="avatar-title badge bg-soft-success text-success rounded fs-16" >{{ index+1 }}</div>
                                                </div>
                                                <div class="flex-grow-1 ms-3 copy" >
                                                    <h5 class="mb-1">{{ item.text }}</h5>
                                                </div>
                                                <div class="hstack gap-3 fs-16" >

                                                    <!-- Видалити пункт -->
                                                    <button 
                                                        v-if="perms[2000] || (perms[372] && this.form.createWorkerId == this.user.userid)"
                                                        @click="showRemoveItem.item = item.opportunityId; showRemoveItem.show = true; showRemoveItem.type = 'opportunities'"
                                                        class="copy btn btn-soft-danger btn-icon"
                                                    >
                                                        <i class="bx bx-trash"></i>
                                                    </button>

                                                    <!-- Відредагувати пункт -->
                                                    <button 
                                                        v-if="perms[2000] || (perms[372] && this.form.createWorkerId == this.user.userid)"
                                                        @click="getElement(item, 'opportunities', 'edit')"
                                                        class="copy btn btn-soft-dark btn-icon"
                                                    >
                                                        <i class="ri-pencil-fill"></i>
                                                    </button>

                                                    <!-- Скопіювати пункт -->
                                                    <button 
                                                        @click="copy(item, 'item')"
                                                        class="copy btn btn-soft-info btn-icon"
                                                    >
                                                        <i class="ri-file-copy-2-line"></i>
                                                    </button>

                                                    <!-- Відкрити детальне вікно -->
                                                    <button 
                                                        @click="getElement(item, 'opportunities', 'open')"
                                                        class="copy btn btn-soft-secondary btn-icon"
                                                    >
                                                        <i class="ri-eye-line"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </template>
                                        
                                        <template v-else>
                                            <div
                                                class="border border-dashed p-2 rounded mb-2 mt-1 text-center" 
                                            >
                                                <span class="fs-14">{{ $t('NonewFeaturesBeenAdded') }}</span>
                                            </div>
                                        </template>

                                        <div class="d-flex mt-2" >

                                            <!-- Копіювати -->
                                            <button 
                                                @click="copy(form.opportunities, 'items', '🔥 Нові можливості')"
                                                v-if="form.opportunities.length > 0"
                                                type="button" 
                                                :class="`btn btn-soft-secondary `"
                                                :title="$t('toCopy')" 
                                                style="margin-top: 10px;margin-right: 5px;"
                                            >
                                                <i class="ri-file-copy-2-line align-bottom"></i>
                                            </button>

                                            <!-- Кнопка додавання опису -->
                                            <button 
                                                v-if="perms[2000] || (perms[372] && this.form.createWorkerId == this.user.userid)"
                                                @click="openEditModal('opportunities')" 
                                                type="button" 
                                                class="mt-2 btn btn-soft-info waves-effect waves-light w-100 fw-semibold"
                                            >
                                               {{ $t('AddnewFeatures') }}
                                            </button>
                                        </div>
                                    </b-card>

                                    <b-card>
                                        <h5 class="fw-semibold text-uppercase mb-3 border-bottom pb-3">🙌 {{ $t('Improved') }}</h5>
                                        
                                        <template 
                                            v-if="form.improvements.length > 0"
                                            v-for="(item, index) in this.form.improvements" 
                                            :key="index"
                                        >
                                            <div class="d-flex align-items-center border border-dashed p-2 rounded mb-2 mt-1" >
                                                <div class="avatar-xs copy" >
                                                    <div class="avatar-title badge bg-soft-success text-success rounded fs-16" >{{ index+1 }}</div>
                                                </div>
                                                <div class="flex-grow-1 ms-3 copy" >
                                                    <h5 class="mb-1">{{ item.text }}</h5>
                                                </div>
                                                <div class="hstack gap-3 fs-16" >

                                                    <!-- Видалити пункт -->
                                                    <button 
                                                        v-if="perms[2000] || (perms[372] && this.form.createWorkerId == this.user.userid)"
                                                        @click="showRemoveItem.item = item.improvementId; showRemoveItem.show = true; showRemoveItem.type = 'improvements'"
                                                        class="copy btn btn-soft-danger btn-icon"
                                                    >
                                                        <i class="bx bx-trash"></i>
                                                    </button>

                                                    <!-- Відредагувати пункт -->
                                                    <button 
                                                        v-if="perms[2000] || (perms[372] && this.form.createWorkerId == this.user.userid)"
                                                        @click="getElement(item, 'improvements', 'edit')"
                                                        class="copy btn btn-soft-dark btn-icon"
                                                    >
                                                        <i class="ri-pencil-fill"></i>
                                                    </button>

                                                    <!-- Скопіювати пункт -->
                                                    <button 
                                                        class="copy btn btn-soft-info btn-icon"
                                                        @click="copy(item, 'item')"
                                                    >
                                                        <i class="ri-file-copy-2-line"></i>
                                                    </button>

                                                    <!-- Відкрити детальне вікно -->
                                                    <button 
                                                        @click="getElement(item, 'improvements', 'open')"
                                                        class="copy btn btn-soft-secondary btn-icon"
                                                    >
                                                        <i class="ri-eye-line"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </template>
                                        
                                        <template v-else>
                                            <div
                                                class="border border-dashed p-2 rounded mb-2 mt-1 text-center" 
                                            >
                                                <span class="fs-14">{{ $t('NoImprovementItemsAdded') }}</span>
                                            </div>
                                        </template>

                                        <div class="d-flex mt-2" >

                                            <button 
                                                v-if="form.improvements.length > 0"
                                                @click="copy(form.improvements, 'items', '🙌 Покращено')"
                                                type="button" 
                                                :class="`btn btn-soft-secondary `"
                                                :title="$t('toCopy')" 
                                                style="margin-top: 10px;margin-right: 5px;"
                                            >
                                                <i class="ri-file-copy-2-line align-bottom"></i>
                                            </button>

                                            <!-- Кнопка додавання опису -->
                                            <button 
                                                v-if="(perms[372] && this.form.createWorkerId == this.user.userid)"
                                                @click="openEditModal('improvements')" 
                                                type="button" 
                                                class="mt-2 btn btn-soft-info waves-effect waves-light w-100 fw-semibold" 
                                            >
                                                {{ $t('AddImprovements') }}
                                            </button>
                                        </div>

                                    </b-card>

                                    <b-card>
                                        <h5 class="fw-semibold text-uppercase mb-3 border-bottom pb-3">💪 {{ $t('Corrected') }}</h5>
                                        
                                        <template 
                                            v-if="form.fixed.length > 0"
                                            v-for="(item, index) in this.form.fixed" 
                                            :key="index"
                                        >
                                            <div class="d-flex align-items-center border border-dashed p-2 rounded mb-2 mt-1" >
                                                <div class="avatar-xs copy" >
                                                    <div class="avatar-title badge bg-soft-success text-success rounded fs-16" >{{ index+1 }}</div>
                                                </div>
                                                <div class="flex-grow-1 ms-3 copy" >
                                                    <h5 class="mb-1">{{ item.text }}</h5>
                                                </div>
                                                <div class="hstack gap-3 fs-16" >

                                                    <!-- Видалити пункт -->
                                                    <button 
                                                        v-if="perms[2000] || (perms[372] && this.form.createWorkerId == this.user.userid)"
                                                        @click="showRemoveItem.item = item.fixedId; showRemoveItem.show = true; showRemoveItem.type = 'fixed'"
                                                        class="copy btn btn-soft-danger btn-icon"
                                                    >
                                                        <i class="bx bx-trash"></i>
                                                    </button>

                                                    <!-- Відредагувати пункт -->
                                                    <button 
                                                        v-if="perms[2000] || (perms[372] && this.form.createWorkerId == this.user.userid)"
                                                        @click="getElement(item, 'fixed', 'edit')"
                                                        class="copy btn btn-soft-dark btn-icon"
                                                    >
                                                        <i class="ri-pencil-fill"></i>
                                                    </button>

                                                    <!-- Скопіювати пункт -->
                                                    <button 
                                                        class="copy btn btn-soft-info btn-icon"
                                                        @click="copy(item, 'item')"
                                                    >
                                                        <i class="ri-file-copy-2-line"></i>
                                                    </button>

                                                    <!-- Відкрити детальне вікно -->
                                                    <button 
                                                        @click="getElement(item, 'fixed', 'open')"
                                                        class="copy btn btn-soft-secondary btn-icon"
                                                    >
                                                        <i class="ri-eye-line"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </template>
                                        
                                        <template v-else>
                                            <div
                                                class="border border-dashed p-2 rounded mb-2 mt-1 text-center" 
                                            >
                                                <span class="fs-14">{{ $t('NofixItemsAdded') }}</span>
                                            </div>
                                        </template>

                                        <div class="d-flex mt-2" >

                                            <!-- Копіюватит -->
                                            <button 
                                                v-if="form.fixed.length > 0"
                                                 @click="copy(form.fixed, 'items', '💪 Виправлено')"
                                                type="button" 
                                                :class="`btn btn-soft-secondary `"
                                                :title="$t('toCopy')" 
                                                style="margin-top: 10px;margin-right: 5px;"
                                            >
                                                <i class="ri-file-copy-2-line align-bottom"></i>
                                            </button>

                                            <!-- Кнопка додавання опису -->
                                            <button 
                                                v-if="perms[2000] || (perms[372] && this.form.createWorkerId == this.user.userid)"
                                                @click="openEditModal('fixed')" 
                                                type="button" 
                                                class="mt-2 btn btn-soft-info waves-effect waves-light w-100 fw-semibold" 
                                            >
                                                {{ $t('Addcorrections') }}
                                            </button>
                                        </div>
                                    </b-card>


                                </div>
                            </b-tab>
                            <b-tab v-if="this.form.projects.length > 0">
                                <template #title>
                                    <span class="d-block d-sm-none"><i class="ri-task-line"></i></span>
                                    <span class="d-none d-sm-block">{{ $t('navProjects') }}</span> 
                                </template>
                                <div class="mt-1"><projects :obj="this.form.projects" /> </div>
                            </b-tab>
                            <b-tab v-if="this.form.tickets.length > 0" >
                                <template #title>
                                    <span class="d-block d-sm-none"><i class="ri-task-line"></i></span>
                                    <span class="d-none d-sm-block">{{ $t('tickets') }}</span> 
                                </template>
                                <div class="mt-1"><tickets :obj="this.form.tickets" /> </div>
                            </b-tab>
                        </b-tabs>
                    </div>
                </b-col>
            </b-row>

        </template>
        <template v-slot:footer-bottom>
            <!-- <button class="btn link-success fw-medium"  @click="$emit('close')"><i class="ri-close-line me-1 align-middle"></i>{{ $t('close') }}</button> -->
            <button 
                type="button" 
                v-if="perms[373] || perms[2000]"
                @click="dialogShow = true" 
                class="btn btn-danger r waves-effect waves-light">
                {{ $t('Delete') }}
            </button>
            <button class="btn btn-warning r waves-effect waves-light" 
                @click="this.startEdit('projects')"
                v-if="(perms[2000] || perms[372]) && this.form.createWorkerId == this.user.userid && this.form.status != 'pre_release'"
            >
                {{ $t('pre_release') }}
            </button>
            <button class="btn btn-success r waves-effect waves-light" 
                @click="goInRelease"
                v-if="(perms[2000] || perms[372]) && this.form.createWorkerId == this.user.userid && this.form.status == 'pre_release'"
            >
                {{ $t('inRelease') }}
            </button>
            <!-- Копіювати все -->
            <button class="btn btn-info r waves-effect waves-light" 
                @click="copy(form, 'all')"
            >
                {{ $t('toCopy') }}
            </button>
        </template>
    </modal>

    <editdesc v-if="showModal" @close="showModal = false" :obj="this.form" />

    <!-- событие в календарь -->
    <addEvent
        v-if="showAddEvent"
        @close="showAddEvent = false"
        :crItem="crItem"
        @updateCard="updateCard"
    />
    <!-- событие в календарь -->

    <modalremove 
        v-if="dialogShow" 
        @close="dialogShow = false" 
        @remove="toDelete()" 
    />

    <editWindow 
        v-if="this.showEdit == true" 
        @close="this.showEdit = false" 
        :items="this.items" 
        :itemsTickets="itemsTickets" 
        :objdata="this.rows" 
        :id="form.deadlineId" 
        :form="this.form" 
        @getdata="this.updateCard(); this.$emit('getdata')"
    ></editWindow>

    <!-- Модальне вікно додавання/редагування пунктів -->
    <editItems 
        v-if="showEditItems.show"
        @close="closeEditModal"
        :objCard="this.form"
        :type="showEditItems.type"
    />

    <!-- Модальне вікно редагування з деталями по пункту -->
    <editElement 
        v-if="showEditModal.show"
        @close="showEditModal.show = false; this.updateCard()"
        :objCard="this.objCard"
        :objElement="this.showEditModal.dataElement"
        :type="showEditModal.type"
    />

    <!-- Модальне вікно з деталями по пункту -->
    <viewItem 
        v-if="showItemModal.show"
        @close="showItemModal.show = false"
        :objCard="showItemModal.obj"
        :type="showItemModal.type"
        :deadlineId="form.deadlineId"
        :objForm="this.form"
    />

    <!-- Модальне вікно з підтвердженням видалення пункту -->
    <removeBox 
        v-if="showRemoveItem.show"
        @close="showRemoveItem.show = false"
        @deleteItem="removeItem();showRemoveItem.show = false"
    />

</template>

<script>
import editWindow from '../delcategmodal.vue'
import modal from '@/components/modal/modal-lg'
import editdesc from './editdesc'
import projects from './projects'
import tickets from './tickets.vue';
import addEvent from '@/components/calendar/addEvent'
import modalremove from '@/components/modal/modalremove'
import { Updates } from '@/API.js'
import { storeS } from '@/store';
import { mutateUpdate, mutateStatusUpdates } from '@/usabilityScripts/globalMutate.js'
import editItems from './editItem/editItems.vue'; // модальне вікно додавання/редагування пунктів
import editElement from './editItem/index.vue' // модальне вікно додавання/редагування детальної інформації по пункту
import viewItem from './viewItem/index'; // модальне вікно перегляду детальної інформації по пункту
import removeBox from './action/removeItem'  // модальне вікно підтвердження видалення детальної інформації по пункту
import { sendTelegramMessage } from '@/usabilityScripts/logsTelegram.js' // надсилання логу в телеграм

let apiUpdates = new Updates();

export default{
    props: ['objCard', 'ifPrevopen', 'ifNextopen', 'rows'],
    components: { 
        modal, 
        projects, 
        tickets, 
        editdesc,
        addEvent,
        editWindow,
        modalremove,
        editItems,
        editElement,
        viewItem,
        removeBox
    },
    data(){
        return{
            componentKey: 0,
            dialogShow: false,
            items: [],
            itemsTickets: [],
            showEdit: false,
            showAddEvent: false,
            crItem: {
                id: '',
                type: 'deadline',
                name: '',
                description: '',
                date: '',
                time: ''
            },
            form:"",
            showModal: "",
            boxprojects: false,
            boxtickets: false,
            projects:{},
            tickets: {},
            showEditItems: { // налаштування по редагуванню пукнтів
                data: {},
                show: false,
                type: ''
            },
            showEditModal: { // налаштування по редагуванню пункта
                dataElement: '',
                show: false,
                type: ''
            },
            showItemModal: { // налаштування на перегляд пункта
                obj: '',
                show: false,
                type: ''
            },
            showRemoveItem: { // налаштування на видалення пункту
                show: false,
                item: '',
                type: ''
            },
        }
    },
    created(){
        this.getdata();
    },
    methods: {
        goInRelease() {
            let obj ={
                status: "in_release"
            }
            apiUpdates.deadlineStatus(this.form.deadlineId, obj).then(result => {
                if(result.status == 'done') {
                    this.$toast.success(this.$t('success'))
                    this.$emit('getdata')
                    this.$emit('close')
                } else {
                    this.$toast.error(this.$t('error'))
                }
            })
        },
        updateCard(){
            // Оновлення картки
            apiUpdates.getUpdate(this.form.deadlineId).then(result => {
                if(result.status == 'done'){
                    this.form = result.data
                    this.componentKey++
                }
            })
        },
        startEdit(a) {
            // if(a == 'tickets') {
            //     this.items = this.form.tickets
            // } else {
            //     this.items = this.form.projects
            // }
            this.items = this.form.projects;
            this.itemsTickets = this.form.tickets;
            this.showEdit = true
        },
        getdata(){
            this.form = this.objCard
        },
        toDelete(){
            // Видалення оновлення
            apiUpdates.deleteUpdate(this.form.deadlineId).then(result => {
                if(result.status == 'done'){
                    this.$toast.success(this.$t('Removed'));
                    this.eventBus.emit('saveUpdate', true)
                    this.$emit('close')
                } else {
                    this.$toast.error(this.$t('error'));
                }
            })
        },
        prevOpen(){
            this.$emit('close');
            this.$emit('prevOpen', this.form.deadlineId)
        },
        nextOpen(){
            this.$emit('close');
            this.$emit('nextOpen', this.form.deadlineId)
        },
        createEvent(){
            this.crItem.date = this.form.deadlineDate;
            this.crItem.time = '10:00';
            this.crItem.name = this.mutateUpdate(this.form.update);
            this.crItem.description = '';
            this.showAddEvent = true;
        },
        mutateUpdate(e){
            return mutateUpdate(e)
        },
        mutateStatus(item) {
            return mutateStatusUpdates(item)
        },
        openEditModal(type) {
            this.showEditItems.type = type; // встановлюємо тип
            this.showEditItems.data = {}; // очищаємо або встановлюємо початкові дані
            this.showEditItems.show = true; // відкриваємо модальне вікно
        },
        closeEditModal(array, type) {
            switch(type) {
                case 'opportunities':
                    this.form.opportunities = array
                break;

                case 'improvements': 
                    this.form.improvements = array
                break;

                case 'fixed': 
                    this.form.fixed = array
                break;
            }
            this.showEditItems.show = false; // закриваємо модальне вікно
            this.updateCard(); // викликаємо оновлення після закриття
            // this.changCard(this.form); // викликаємо оновлення після закриття
        },
        changCard(form){
            apiUpdates.editUpdate(form.deadlineId, form).then(result => {
                if(result.status === 'done') {
                    this.$toast.success(this.$t('success'))
                    this.updateCard()
                } else {
                    // todo logs
                }
            })
        },
        removeItem(){
            // Видалити елемент

            switch (this.showRemoveItem.type) {

                case 'opportunities': 

                    apiUpdates.deleteOpportunity(this.showRemoveItem.item).then(result => {

                        if(result.status == 'done') {
                            this.form.opportunities = result.data;
                            this.$toast.success(this.$t("Removed"))
                        } else {
                            this.$toast.error(this.$t('error') + " " + result.error[0]);

                            // sendTelegramMessage
                            var errorText = 'Помилка видалення пункту нових можливостей'; 
                            var statusRequest = result.status;
                            var api = 'deleteOpportunity';
                            var fileRequest = 'src/views/changelog/view/index.vue';
                            var params = this.showRemoveItem.item;
                            var response = result

                            // Викликаємо функцію для відправки повідомлення в телеграм
                            sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                            .then(response => console.log('Telegram API response:', response.data))
                            .catch(error => console.error('Telegram API error:', error));
                        }

                    })

                break;
                case 'improvements': 

                    apiUpdates.deleteImprovement(this.showRemoveItem.item).then(result => {

                        if(result.status == 'done') {
                            this.form.improvements = result.data;
                            this.$toast.success(this.$t("Removed"))
                        } else {
                            this.$toast.error(this.$t('error') + " " + result.error[0]);

                            // sendTelegramMessage
                            var errorText = 'Помилка видалення пункту покращення'; 
                            var statusRequest = result.status;
                            var api = 'deleteImprovement';
                            var fileRequest = 'src/views/changelog/view/index.vue';
                            var params = this.showRemoveItem.item;
                            var response = result

                            // Викликаємо функцію для відправки повідомлення в телеграм
                            sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                            .then(response => console.log('Telegram API response:', response.data))
                            .catch(error => console.error('Telegram API error:', error));
                        }

                    })

                break;
                case 'fixed': 

                    apiUpdates.deleteFixed(this.showRemoveItem.item).then(result => {

                        if(result.status == 'done') {
                            this.form.fixed = result.data;
                            this.$toast.success(this.$t("Removed"))
                        } else {
                            this.$toast.error(this.$t('error') + " " + result.error[0]);

                            // sendTelegramMessage
                            var errorText = 'Помилка видалення пункту виправлення'; 
                            var statusRequest = result.status;
                            var api = 'deleteFixed';
                            var fileRequest = 'src/views/changelog/view/index.vue';
                            var params = this.showRemoveItem.item;
                            var response = result

                            // Викликаємо функцію для відправки повідомлення в телеграм
                            sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                            .then(response => console.log('Telegram API response:', response.data))
                            .catch(error => console.error('Telegram API error:', error));
                        }

                    })

                break;

                default: 
                    this.$toast.error(this.$t('error'));
                break
            }
        },
        getElement(item, type, method) {
            // Отримати або відредагувати пункт
            
            switch (type) {

                case 'opportunities': 

                    apiUpdates.getOpportunity(item.opportunityId).then(result => {

                        if(result.status == 'done') {
                            
                            if(method == 'edit'){
                                // Відкриваємо вікно редагування
                                this.showEditModal.type = type;
                                this.showEditModal.dataElement = result.data
                                this.showEditModal.show = true
                            } else {
                                // Відкриваємо вікно перегляду
                                this.showItemModal.obj = result.data
                                this.showItemModal.show = true
                                this.showItemModal.type = type;
                            }
                            
                        } else {
                            console.log(result);
                        }

                    })

                break;
                case 'improvements': 

                    apiUpdates.getImprovement(item.improvementId).then(result => {

                        if(result.status == 'done') {
                            
                            if(method == 'edit'){
                                // Відкриваємо вікно редагування
                                this.showEditModal.type = type;
                                this.showEditModal.dataElement = result.data
                                this.showEditModal.show = true
                            } else {
                                // Відкриваємо вікно перегляду
                                this.showItemModal.obj = result.data
                                this.showItemModal.show = true
                                this.showItemModal.type = type;
                            }
                            
                        } else {
                            console.log(result);
                        }

                    })

                break;
                case 'fixed': 

                    apiUpdates.getFixed(item.fixedId).then(result => {

                        if(result.status == 'done') {
                            
                            if(method == 'edit'){
                                // Відкриваємо вікно редагування
                                this.showEditModal.type = type;
                                this.showEditModal.dataElement = result.data
                                this.showEditModal.show = true
                            } else {
                                // Відкриваємо вікно перегляду
                                this.showItemModal.obj = result.data
                                this.showItemModal.show = true
                                this.showItemModal.type = type;
                            }
                            
                        } else {
                            console.log(result);
                        }

                    })

                break;
                    

                default: 
                    console.log('log');
                break

            }
        },
        copy(item, type, sectionText){
            // Копіювати

            console.log("item", item);
            var textCopy = "";
            
            switch (type) {
                case 'item':
                    textCopy = `Пункт "${item.text}"`
                break;

                case 'items':
                    // Створюємо текст для копіювання
                    textCopy = `${sectionText}:\n`;
                    item.forEach((item, index) => {
                        textCopy += `${index + 1}. ${item.text}\n`;
                    });
                break;

                case 'all':
                    // Форматуємо текст для кожного блоку
                    textCopy = "Нові можливості:\n";
                    this.form.opportunities.forEach((item, index) => {
                        textCopy += `${index + 1}. ${item.text}\n`;
                    });

                    textCopy += "\nПокращено:\n";
                    this.form.improvements.forEach((item, index) => {
                        textCopy += `${index + 1}. ${item.text}\n`;
                    });

                    textCopy += "\nВиправлено:\n";
                    this.form.fixed.forEach((item, index) => {
                        textCopy += `${index + 1}. ${item.text}\n`;
                    });

                break;
                default:
                    console.log('');
                break
                
            }

            navigator.clipboard.writeText(textCopy)
                .then(() => {
                    this.$toast.success(this.$t('Текст скопійовано успішно'))
                })
                .catch(err => {
                    console.error('Помилка при копіюванні:', err);
                });
        }
    },
    mounted(){
        this.eventBus.on('saveProject', (status) => {
            if(status == true) {
                this.getdata();
            }
        }),
        this.eventBus.on('saveTicket', (status) => {
            if(status == true) {
                this.getTickets();
            }
        })
    },
    computed: {
        perms(){
            return storeS.perms
        },
        user(){
            return storeS.userbase
        }
    }
}
</script>